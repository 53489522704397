<template>
  <div class="relate">
    <img :src="logo" alt="" class="logo-img">
    <div style="text-align: center;margin-top: 20px">V{{version}}</div>
    <div class="content">
      村口壹站由{{companyName}}提供技术支持和产品运营。村口壹站为村民提供基础普惠金融服务，以及生活缴费、快递物流、就业医疗等综合便民服务。
    </div>
    <div class="footer">
      <div>{{companyName}}.保留所有权利</div>
      <div>Copyright2020</div>
      <a target="_blank"
         href="https://beian.miit.gov.cn/"
         >APP备案号：苏ICP备2021038873号-2A</a>
    </div>
  </div>
</template>

<script>
import {COMPANY_NAME} from '@/utils/constantNum'
import {getVersionInfo} from '@/utils/api'
import {uwStatisticAction} from "../../utils/util";
export default {
  data() {
    return {
      logo: require('../../assets/img/logo.png'),
      companyName:COMPANY_NAME,
      version:''
    }
  },
  mounted() {
    if (this.$route.query.jumpFrom !=undefined && this.$route.query.version != undefined){
      console.log("if:",this.$route.query.jumpFrom)
      this.version = this.$route.query.version
    }else{
      console.log("else:")
      this.getVersion()
    }
    uwStatisticAction('/relate','关于')
  },
  methods:{
    getVersion(){
      getVersionInfo({
        platform: 'h5'
      }).then(res=>{
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            this.version = res.data.data.version      
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.relate{
  background: #f5f5f5;
  height: 100%;
  text-align: center;
  padding: 0.3rem;
  .logo-img{
    width: 1.8rem;
    border-radius: 50%;
    margin-top: 1rem;
  }
  .content{
    margin-top: 0.5rem;
    padding-top: 0.2rem;
    border-top: 0.01rem solid #444;
    text-align: left;
  }
  .footer{
    font-size: 0.28rem;
    width: 100%;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%)
  }
}
</style>
